import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, IconButton, Stack, Modal, Select, MenuItem, Autocomplete, TextField } from '@mui/material';
import { styles, OrderButton } from '../styles';
import Utils from '../../../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import { editLabelAssignment } from '../../../../services/Gateway/RestAPIHandler';
import SimpleSnackbar from '../../../../customWidgets/Snackbar';
import { searchLabelAssignmentAction } from '../Store/actions';

function EditLabelAssignment({ openModal, closeModal, rowsData, currentPage }) {
    const [open, setOpen] = useState(false);
    const [alertType, setAlertType] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [inputData, setInputData] = useState({ division: [], ageGroup: [], product: [], country: [], customerLis: [], labelCode: [], specialUsages: [], label: [] })
    const list = useSelector(state => state.labelAssignmentList)
    const dispatch = useDispatch();
    const [labelCode, setLabelCode] = useState((rowsData.map(ele => ele?.labelCode).join(',')));
    const [division1, setDivision1] = useState((rowsData.map(ele => ele?.divisionDesc).join(',')));
    const [age, setAge] = useState((rowsData.map(ele => ele?.ageGroup).join(',')));
    const [specialdesc, setSpecialdesc] = useState((rowsData.map(ele => ele?.specialUsageField).join(',')));
    const [isspecialusage, setIsspecialusage] = useState((rowsData.map(ele => ele?.isSpecialUsage).join(',')));
    const [product, setProduct] = useState((rowsData.map(ele => ele?.productGroup).join(',')))
    const [country, setCountry] = useState((rowsData.map(ele => ele.countryDesc).join(',')))
    const [customer, setCustomer] = useState((rowsData.map(ele => ele?.customerNumber).join(',')))
    const [label, setLabel] = useState('')
    const GRID_FETCH_SIZE = 7;
    const specialOptionList = ["Y", "N"];
    const divisionList = inputData.division.map(ele => ele.value);
    // setDivision(((rowsData.map(ele => ele?.divisionDesc))));
    const [productOptions, setProductOptions] = React.useState([]);
    const [labelOptions, setLabelOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [inputSpecialValue, setInputSpecialValue] = React.useState('');
    const [inputLabelValue, setInputLabelValue] = React.useState('');

    const selectedValues = React.useMemo(
        () => inputData.division.filter((v) => v.value),
        [inputData],
    );

    React.useEffect(() => {
        setAge(rowsData.map(ele => ele?.ageGroup).join(','));
        setDivision1(rowsData.map(ele => ele?.divisionDesc).join(','));
        setInputValue(rowsData.map(ele => ele?.productGroup).join(','));
        setCustomer(rowsData.map(ele => ele?.customerNumber).join(','));
        setCountry(rowsData.map(ele => ele.countryDesc).join(','));
        setIsspecialusage(rowsData.map(ele => ele?.isSpecialUsage).join(','));
        setInputSpecialValue(rowsData.map(ele => ele?.specialUsageField).join(','));
        setInputLabelValue(rowsData.map(ele => ele?.labelCode).join(','))
    }, [rowsData])




    /* Values are hardcoded temporarily till get data from API */
    React.useEffect(() => {
        if (Object.keys(list.order).length > 0 && !Utils.isEmpty(list.order.data[0])) {

            setInputData(list.order.data[0].referenceMap)
            if (list.order.data[0].referenceMap.product !== undefined) {
                setProductOptions(list.order.data[0].referenceMap.product.map(ele => ele.value));
            }
            if (list.order.data[0].referenceMap.labelCode !== undefined) {
                setLabelOptions(list.order.data[0].referenceMap.labelCode.map(ele => ele));
            }
        }
    }, [list.order])


    const handleChange = (event, newInputValue, reason) => {
        setInputLabelValue(newInputValue);
    };

    const handleAgeChange = (event, newInputValue, reason) => {
        setAge(newInputValue);
    };

    const handleCustomerChange = (event, newInputValue, reason) => {
        setCustomer(newInputValue);
    };


    const handleProductChange = (event, newInputValue, reason) => {
        setInputValue(newInputValue);
    };

    const handleCountryChange = (event, newInputValue, reason) => {
        setCountry(newInputValue);
    };

    const onInputDivisionChange = (event, newInputValue, reason) => {
        setDivision1(newInputValue);
        setInputValue('');
        setInputLabelValue('');
        const code = (newInputValue === 'FOOTWEAR') ? '1' : '2';
        setProductOptions(inputData.product.filter(ele => ele.division.includes(code)).map(ele => ele.value));
        setLabelOptions(inputData.label.filter(ele => ele.division.includes(code)).map(ele => ele.labelCode))
    }

    const handleSpecialChange = (event, newInputValue, reason) => {
        // const { target: { value } } = event;
        setInputSpecialValue(newInputValue);
        // document.getElementById("special").innerText = value;

        const specialUsageVal = isspecialusage;
        if (specialUsageVal === 'N') {
            //document.getElementById("special").innerHTML = "";
            setInputSpecialValue('');
            setAlertType('error')
            setAlertMessage("Is Special Usage should be Yes for selecting Special Usage Description")
            setOpen(true)
        }
    };

    const handleSpecialUsageChange = (event, newInputValue, reason) => {
        setIsspecialusage(newInputValue);
        setInputSpecialValue('');
        // document.getElementById("specialUsage").innerText = value;
        //document.getElementById("special").innerHTML = "";
        // if(value === 'N') {

        // } else  if(value === 'Y') {
        //     document.getElementById("special").innerHTML = "";
        // }
    };

    const handleGridSave = async () => {
        const specialVal = inputSpecialValue;
        const specialUsageVal = isspecialusage;
        const ageVal = age;
        const customerVal = customer;
        const divisionVal = division1;
        const labelCodeVal = inputLabelValue;
        const productVal = inputValue;
        const countryVal = country;
        console.log(customer);
        if (divisionVal === '' || divisionVal === "" || divisionVal === null) {
            setAlertType('error')
            setAlertMessage("Select Division")
            setOpen(true)
        } else if (ageVal === '' || ageVal === "" || ageVal === null) {
            setAlertType('error')
            setAlertMessage("Select Age Group")
            setOpen(true)
        } else if (countryVal === '' || countryVal === "" || countryVal === null) {
            setAlertType('error')
            setAlertMessage("Select Country")
            setOpen(true)
        } else if (customerVal === '' || customerVal === "" || customerVal === null) {
            setAlertType('error')
            setAlertMessage("Select Customer")
            setOpen(true)
        } else if (productVal === '' || productVal === "" || productVal === null) {
            setAlertType('error')
            setAlertMessage("Select Product Group")
            setOpen(true)
        } else if (specialUsageVal === '' || specialUsageVal === "" || specialUsageVal === null) {
            setAlertType('error')
            setAlertMessage("Select Special Usage")
            setOpen(true)
        } else if (labelCodeVal === '' || labelCodeVal === "" || labelCodeVal === null) {
            setAlertType('error')
            setAlertMessage("Label Code Required")
            setOpen(true)
        }
        else if (specialUsageVal === 'Y') {
            if (specialVal === '' || specialVal === "" || specialVal === null) {
                setAlertType('error')
                setAlertMessage("Select special usage description")
                setOpen(true)
            } else {
                handleSave();
            }
        } else {
            handleSave();
        }
    }
    //Object.values(data).map(d => <td>{d}</td>);


    const handleSave = async () => {
        const ageVal = age;
        const customerVal = customer;
        const divisionVal = division1;
        const labelCodeVal = inputLabelValue;
        const productVal = inputValue;
        const countryVal = country;
        const specialVal = inputSpecialValue;
        const specialUsageVal = isspecialusage;
        const alaIdValue = document.getElementById("alaId").value;


        if ((specialUsageVal === 'Y') && (specialVal === "")) {
            setAlertType('error')
            setAlertMessage("Select special usage description")
            setOpen(true)
        } else {
            editLabelAssignment({
                "artLabAssignmentId": alaIdValue,
                "ageGroup": ageVal,
                "customerNumber": customerVal,
                "division": {
                    "code": (divisionVal === 'FOOTWEAR') ? '1' : '2'
                },
                "labelCode": labelCodeVal,
                "productGroup": productVal,
                "shippingCountry": { "countryName": countryVal },
                "specialUsageField": specialVal,
                "isSpecialUsage": specialUsageVal,
            })
                .then(([response, json]) => {
                    if (response.status === 200) {
                        if (json.results === undefined) {
                            setAlertType('error')
                            setAlertMessage("Same ALA combination already exists")
                            setOpen(true)
                        }
                        else if (json.results[0] === 'SUCCESS') {
                            setAlertType('success')
                            setOpen(true)
                            closeModal(false)
                            setAlertMessage('Label Assignment Modified Successfully')
                            setAge('');
                            setDivision1('');
                            setProduct('');
                            setCustomer('');
                            setCountry('');
                            setIsspecialusage('');
                            setSpecialdesc('');
                            setLabelCode('');
                            dispatch(searchLabelAssignmentAction({
                                "ascDesc": "",
                                "fetchSize": GRID_FETCH_SIZE,
                                "filterSearch": "",
                                "pageNumber": currentPage,
                                "sortBy": ""
                            }))
                        } else {
                            setAlertType('error')
                            setAlertMessage("Label Assignment Error ")
                            setOpen(true)
                        }
                    }
                    else {
                        setAlertType('error')
                        setAlertMessage("Unable to Modify Label Assignment")
                        setOpen(true)
                    }
                
                })
        }
    }

    return (
        <>
            <SimpleSnackbar setOpen={setOpen} open={open} message={alertMessage} messagetype={alertType} />
            <Modal
                open={openModal}
                onClose={() => closeModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.popupStyle} style={{ overflowY: 'scroll' }}>
                    <Typography component='div' style={styles.popupContent.title}>
                        <Typography component='span' style={styles.popupContent.titleText}>Edit Label Assignment</Typography>
                        <IconButton id="close-modal-popup" data-testid="close-modal-popup" onClick={() => closeModal(false)} style={styles.popupContent.closeButton}>
                            <CloseIcon />
                        </IconButton>
                    </Typography>

                    <Stack>
                        <Box sx={styles.popupContent.row}>

                            <Autocomplete
                                disablePortal
                                id="division"
                                options={divisionList}
                                onChange={onInputDivisionChange}
                                getOptionLabel={(option) => {
                                    return option;
                                }}
                                defaultValue={((rowsData.map(ele => ele?.divisionDesc).join(',')))}
                                style={{ width: 230 }}
                                renderInput={(params8) => (
                                    <TextField {...params8} label="Division" variant="outlined" />
                                )}//
                            />
                            <Autocomplete
                                id="age"
                                options={inputData.ageGroup.map(ele => ele.value)}
                                defaultValue={(rowsData.map(ele => ele?.ageGroup).join(','))}
                                onChange={handleAgeChange}
                                style={{ width: 230 }}
                                renderInput={(params7) => (
                                    <TextField {...params7} label="Age Group" variant="outlined" />
                                )}
                            />
                        </Box>


                        <Box sx={styles.popupContent.row}>
                            <Autocomplete
                                id="country"
                                options={inputData.country.map(ele => ele.countryName)}
                                onChange={handleCountryChange}
                                defaultValue={rowsData.map(ele => ele?.countryDesc).join(',')}
                                style={{ width: 230 }}
                                renderInput={(params9) => (
                                    <TextField {...params9} label="Country" variant="outlined" />
                                )}
                            />
                            <Autocomplete
                                id="customer"
                                options={inputData.customerLis.map(ele => ele.customerCode)}
                                onChange={handleCustomerChange}
                                defaultValue={rowsData.map(ele => ele?.customerNumber).join(',')}
                                style={{ width: 230 }}
                                renderInput={(params10) => (
                                    <TextField {...params10} label="Customer" variant="outlined" />
                                )}
                            />
                            {/*    <Typography component='span'>Is Special Usage</Typography>
                            <Select id="specialUsage"
                                displayEmpty sx={styles.popupContent.dropdown}
                                value={rowsData.map(ele => ele?.isSpecialUsage).join(',')}
                                onChange={handleSpecialUsageChange}
                            >
                                {specialOptionList.map(ele => <MenuItem key={ele} value={ele}>{ele}</MenuItem>)}
                            </Select>
                                */}

                        </Box>
                        <Box sx={styles.popupContent.row}>

                            <Autocomplete
                                id="specialoption"
                                options={specialOptionList.map(ele => ele)}
                                onChange={handleSpecialUsageChange}
                                defaultValue={rowsData.map(ele => ele?.isSpecialUsage).join(',')}
                                style={{ width: 230 }}
                                renderInput={(params11) => (
                                    <TextField {...params11} label="Is Special Usage" variant="outlined" />
                                )}
                            />
                            <Autocomplete
                                id="specialdescription"
                                value={inputSpecialValue}
                                options={inputData.specialUsages.map(ele => ele.description)}
                                onChange={handleSpecialChange}
                                defaultValue={rowsData.map(ele => ele?.specialUsageField).join(',')}
                                style={{ width: 230 }}
                                renderInput={(params12) => (
                                    <TextField {...params12} label="Special Usage Description" variant="outlined" />
                                )}
                            />

                        </Box>
                        <Box sx={styles.popupContent.row}>

                            <Autocomplete
                                id="product-group"
                                value={inputValue}
                                options={productOptions}
                                onChange={handleProductChange}
                                defaultValue={rowsData.map(ele => ele?.productGroup).join(',')}
                                style={{ width: 480 }}
                                renderInput={(params8) => (
                                    <TextField {...params8} label="Product Group Description" variant="outlined" />
                                )}
                            />
                        </Box>

                        <Box sx={styles.popupContent.row}>
                            {/*  <Typography component='span'>Label code</Typography>
                            <Select id="labelCode"
                                displayEmpty sx={styles.popupContent.dropdown}
                                value={rowsData.map(ele => ele?.labelCode).join(',')}
                                onChange={handleChange} >
                                {inputData.labelCode.map(ele => <MenuItem key={ele} value={ele}>{ele}</MenuItem>)}
                            </Select> */}
                            <Autocomplete
                                id="labelCode"
                                value={inputLabelValue}
                                options={labelOptions}
                                defaultValue={rowsData.map(ele => ele?.labelCode).join(',')}
                                onChange={handleChange}
                                renderInput={(params13) => (
                                    <TextField {...params13} label="Label Code" />
                                )}
                                sx={{ width: '480px' }}
                            />

                        </Box>
                        <Box sx={styles.popupContent.hideLabel}>
                            <input id="alaId" value={rowsData.map(ele => ele?.artLabAssignmentId).join(',')} ></input>
                        </Box>
                        <Box sx={styles.popupContent.button}>
                            <OrderButton onClick={handleGridSave}>UPDATE</OrderButton>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
}

export default EditLabelAssignment;