import React, { useRef } from 'react';
import { LoginStyle, LoginButton } from './styles';
import logo from '../../assets/reebok_logo.png';
import { Box, Typography, Divider } from '@mui/material';
import Utils from '../../utils/Utils';
import SimpleSnackbar from '../../customWidgets/Snackbar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AuthPage from './Auth/auth';
import RedirectPage from './Auth/redirect';
import envConfig from "../../../config/env/environment";

export function Login(props) {

    const username = useRef();
    const password = useRef();
    const [openAlert, setOpenAlert] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [alertBar, setAlertBar] = React.useState({
        alertMessage: "",
        alertType: "success"
    })

    const handleLoginPage = (event) => {
        if(Utils.isEmpty(username.current.value) || Utils.isEmpty(password.current.value)) {
            setOpenAlert(true);
            setAlertBar({
                alertMessage: "Username and Password cannot be blank",
                alertType: "error"
            })
            return;
        }
        props.handleLogin(username.current.value, password.current.value);
    }

    const handleSubmit = (event) => {
        if(event.keyCode === 13) {
            handleLoginPage(event);
        }
    }

    const handleShowPassword = (event) => {
        setShowPassword(!showPassword);
    }

    return (
        <Box id="login" data-testid="login-page" style={LoginStyle.container}>
            <SimpleSnackbar setOpen={setOpenAlert} open={openAlert}  message={alertBar["alertMessage"]} messagetype={alertBar["alertType"]} />
            <Box style={LoginStyle.container.leftPanel}>
                <img style={LoginStyle.container.leftPanel.img} src={logo} alt="Reebok" />
                <Typography component='span' sx={LoginStyle.container.leftPanel.title}>Labelling Application Suite</Typography>
            </Box>
            <Divider orientation="vertical" flexItem sx={LoginStyle.container.divider} />
            <Box style={LoginStyle.container.rightPanel}>
                <Box style={LoginStyle.container.rightPanel.loginField}>
                    <Typography component='span' sx={LoginStyle.container.rightPanel.signin}>Sign In</Typography>
                    <Box style={LoginStyle.container.rightPanel.align}>
                        <Typography component='span'>Username</Typography>
                        <input type="text" data-testid="txt-login-user" style={LoginStyle.container.rightPanel.loginField.textBox} ref={username} onKeyDown={handleSubmit} />
                    </Box>
                    <Box style={LoginStyle.container.rightPanel.align}>
                        <Typography component='span'>Password</Typography>
                        <Box style={LoginStyle.container.rightPanel.loginField.password}>
                            <input type={ showPassword ? "text" : "password" } data-testid="txt-login-password" style={LoginStyle.container.rightPanel.loginField.password.textbox} ref={password} onKeyDown={handleSubmit} />
                            { showPassword ? <VisibilityOffIcon data-testid="pwd-visibility-off-icon" style={LoginStyle.container.rightPanel.loginField.password.icon} onClick={handleShowPassword} /> : <VisibilityIcon data-testid="pwd-visibility-icon" style={LoginStyle.container.rightPanel.loginField.password.icon} onClick={handleShowPassword} /> }
                        </Box>
                    </Box>
                    <LoginButton data-testid="btn-login" onClick={handleLoginPage}>Login</LoginButton>
                </Box>
            </Box>
        </Box>
    )
}

export default function UnauthorizedLayout(props) {
    const isAuthURL = (window.location.pathname === '/auth');
    const isAuthRedirect = (window.location.pathname === '/redirect');

       // const handleLogin = (uname,pwd) => {
          //  props.handleLogin(uname,pwd);
      //  }
    
    
    let display;
    if(isAuthRedirect) {
        display = <RedirectPage />
    }
    else if(isAuthURL) {
        display = <AuthPage />
    }
    else {
        // display = <Login handleLogin={handleLogin} />
        window.location.assign(envConfig.oneLogin.loginURL);
    }

    return (
        <>
            {display}
        </>
    )
}