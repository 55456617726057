import React, { useRef, useState, useMemo, useEffect } from 'react'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import logo from '../../../assets/excelicon.png';
import { Box } from '@mui/material';
import { styles,StyledAgGridReact } from './styles';
import * as XLSX from 'xlsx';
import SimpleSnackbar from '../../../customWidgets/Snackbar';
import { searchLabelAssignmentAction } from './Store/actions';
import GridPagination from '../../../customWidgets/GridPagination/index';
import {useDispatch, useSelector  } from 'react-redux';
import Buttons from './Buttons';
import {useLocation, useNavigate } from "react-router-dom";
import { addLabelAssignmentRow,apiDeleteLabelAssignment,apisearchLabelAssignmentList} from '../../../services/Gateway/RestAPIHandler';
import Utils from '../../../utils/Utils';
import Loader from '../../../customWidgets/Loader'
import AddLabelAssignment from './components/addAssignment';
import AccessLevelContext from '../../../../accessLevel-context';
import EditLabelAssignment from './components/EditAssignment';

function LabelAssignment(){
    const navigate = useNavigate()
    const [labelIds,setLabelIds] = useState('')
    const [open,setOpen] = useState(false);
    const [labelRows,setLabelRows] = React.useState([])
    const [alertType,setAlertType] = useState("success");
    const [alertMessage,setAlertMessage] = useState("");
    const gridRef = useRef();
    const location =useLocation()
    const accessLevel= React.useContext(AccessLevelContext)
    const dataToExcel = (data) => {
      const newData = data.map(ele => {
        return {
          // "Id" : ele?.artLabAssignmentId,
          "Division" : ele?.divisionDesc,
          "Age Group" : ele?.ageGroup,
          "Shipping Country" : ele?.countryDesc,
          "Product Group" : ele?.productGroup,
          "Customer Number" : ele?.customerNumber,
          "Label Code" : ele?.labelCode
          
                }
      })
      
      const worksheet = XLSX.utils.json_to_sheet(newData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
      XLSX.writeFile(workbook, "DownloadArticleLabelAssignment.xlsx")
      setLoading(false)
    }
    const exportToExcel = () => {
      setLoading(true)
      
            if(labelRows.length===0){
              apisearchLabelAssignmentList(
                {...req,fetchSize:10000,pageNumber: 1}
              ).then(([response, json])=> {
                if(response.status===200 && !Utils.isEmpty(json.results)){
                const data = json.results[0].data.content;
                dataToExcel(data)
                }
                else{
                  setOpen(true)
                  setAlertType('error')
                  setAlertMessage("No data to export")
                  setLoading(false)
                }
              })
            }
            else{
              dataToExcel(labelRows)
            }

          
    }
    const checkboxSelection = function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    };
    const headerCheckboxSelection = function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    };
    const columnDef = [
      { field: 'chkBox', headerName: '', resizable: false, checkboxSelection: checkboxSelection, headerCheckboxSelection: headerCheckboxSelection, width: 50 },
      { field: 'artLabAssignmentId', headerName: 'Id', editable: true, width: 200, headerClass: 'grid-header',hide:true },
      { field: 'divisionDesc', headerName: 'Division', editable: true, width: 200, headerClass: 'grid-header',resizable:true },
      { field: 'ageGroup', headerName: 'Age Group', editable: true, width: 200, headerClass: 'grid-header',resizable:true },
      { field: 'countryDesc', headerName: 'Shipping Country', editable: true, width: 200, headerClass: 'grid-header',resizable:true },
      { field: 'productGroup', headerName: 'Product Group', width: 200, editable: true, headerClass: 'grid-header',resizable:true },
      { field: 'customerNumber', headerName: 'Customer Number', editable: true, width: 200, headerClass: 'grid-header',resizable:true },
      { field: 'labelCode', headerName: 'Label Code', width: 200, editable: true, headerClass: 'grid-header',resizable:true },
      { field: 'isSpecialUsage', headerName: 'Is Special Usage', width: 200, editable: true, headerClass: 'grid-header',resizable:true },
      { field: 'specialUsageField', headerName: 'Special Usage Description', width: 200, editable: true, headerClass: 'grid-header',resizable:true },
    ];
   
    const [currentPage, setCurrentPage] = useState(1);
    const [loading,setLoading] = useState(true)
    const GRID_FETCH_SIZE = 7;
    const list = useSelector(state => state.labelAssignmentList);
    const [req,setReq]= useState(list.request)    
    const dispatch = useDispatch();
    useEffect(() => {
        if(accessLevel.accessLevel!=='A01'){
          navigate('/')
        }
        else{
          if(location.state!==null){
            if(location.state.Status==='*'){
              dispatch(searchLabelAssignmentAction({
                "ascDesc": "",
                "fetchSize": GRID_FETCH_SIZE,
                "filterSearch": "",
                "pageNumber": 1,
                "sortBy": ""
              }))
              }
            }
           else{
          if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
            dispatch(searchLabelAssignmentAction(req))
          }
          else{
            dispatch(searchLabelAssignmentAction({
              "ascDesc": "",
              "fetchSize": GRID_FETCH_SIZE,
              "filterSearch": "",
              "pageNumber": currentPage,
              "sortBy": ""
            }))
          }}
        }



      
    },[])//eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => {
      if(!Utils.isEmpty(list.order)  && !Utils.isEmpty(list.order.data)){
        setLoading(false)
      }
    },[currentPage])//eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      setCurrentPage(req.pageNumber)
    },[req]);
    const onSelectionChange = (event) => {
      const selectedRows = event.api.getSelectedRows();
      if (!Utils.isEmpty(selectedRows)){
        const labelIdslist=selectedRows.map(ele => ele?.artLabAssignmentId).join(',')
        setLabelIds(labelIdslist)
        setLabelRows(selectedRows)
      }
      else{
        setLabelRows([])
        setLabelIds('')
      }
    }

    const gotoKeyDown = (event, value) => {
      
      if(event.keyCode === 13 && parseInt(value) >= 1  && parseInt(value) <= getTotalPages.totalPages && currentPage!==parseInt(value)) {
        setCurrentPage(parseInt(value));
        showGridLoader();
        setLabelRows([]);
        if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
          dispatch(searchLabelAssignmentAction({ ...req,fetchSize: GRID_FETCH_SIZE, pageNumber: parseInt(value) }));
        }
        else{
          dispatch(searchLabelAssignmentAction({
            "ascDesc": "",
            "fetchSize": GRID_FETCH_SIZE,
            "filterSearch": "",
            "pageNumber": parseInt(value),
            "sortBy": ""
          }))
        }
      }
    }

    const showGridLoader = () => {
      gridRef.current.api.showLoadingOverlay();
    }

    const getTotalPages = useMemo(() => {
      setReq(list.request)
      const orderData = !Utils.isEmpty(list.order) ? list.order?.data[0]?.data : [];
      if(!Utils.isEmpty(list.order)) {
        return Utils.isEmpty(list.order?.data) ?
          {
            totalPages: 1,
            totalElements: 1
          } : {
            totalPages: orderData?.totalPages,
            totalElements: orderData?.totalElements
          };
      }
      else
        return {
          totalPages: 1,
          totalElements: 1
        };
    },[list.order]);//eslint-disable-line react-hooks/exhaustive-deps
  
    const handleGridPagination = (event, thisPage) => {
      if(currentPage!==thisPage){
        setLabelRows([])
        showGridLoader()
        setCurrentPage(thisPage)

        if(req.filterSearch!=='' && req.hasOwnProperty('filterSearch')){
          dispatch(searchLabelAssignmentAction({ ...req,fetchSize: GRID_FETCH_SIZE, pageNumber: thisPage }));
        }
        else{
          dispatch(searchLabelAssignmentAction({
            "ascDesc": "",
            "fetchSize": GRID_FETCH_SIZE,
            "filterSearch": "",
            "pageNumber": thisPage,
            "sortBy": ""
          }))
        }
      }
    }
    
    const handleGridAdd = (event) => {
      setOpenAddLabel(true);
    }
    const handleGridSave = (event) => {
      const selectRows = gridRef.current.api.getSelectedRows();
      const selectedRowsArray = selectRows[0]
      addLabelAssignmentRow(selectedRowsArray)
    }
    const handleGridDelete = (event) => {
      apiDeleteLabelAssignment({'labelIds':labelIds}).then(([response, json]) => {
        if(response.status===200 && json.status==="SUCCESS"){
          setOpen(true)
          setAlertType('success')
          setAlertMessage('Successfully Deleted')
          dispatch(searchLabelAssignmentAction({
            "ascDesc": "",
            "fetchSize": GRID_FETCH_SIZE,
            "filterSearch": "",
            "pageNumber": 1,
            "sortBy": ""
          }))
          setLabelIds('')
      }
      else{
        setOpen(true)
        setAlertType('error')
        setAlertMessage('Unable to delete selected labels')
      }
    })
    }

  const handleGridEdit = (event) => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if(selectedRows.length > 1) {
        setOpen(true)
        setAlertType('error')
        setAlertMessage('Please select only one record for modification')
    } else{
      if (!Utils.isEmpty(selectedRows)) {
        list.rows = selectedRows;
        const labelIdslist = selectedRows.map(ele => ele?.artLabAssignmentId).join(',')
        setLabelIds(labelIdslist)
        setLabelRows(selectedRows)
      }
      else {
        setLabelRows([])
        setLabelIds('')
      }
      setOpenEditLabel(true);
    }
  }

    const [openAddLabel, setOpenAddLabel] = useState(false);
    const [openEditLabel, setOpenEditLabel] = useState(false);


return(
  <Box style={styles.gridContainer}>
    <Loader open={loading}/>
    <img onClick={() => { exportToExcel() }} src={logo} style={styles.excelicon} alt={"Export Excel"} />
    <SimpleSnackbar setOpen={setOpen} open={open}  message={alertMessage} messagetype={alertType} />
    <AddLabelAssignment openModal={openAddLabel} closeModal={setOpenAddLabel} currentPage={currentPage}/>
    <EditLabelAssignment openModal={openEditLabel} closeModal={setOpenEditLabel} rowsData={labelRows} currentPage={currentPage}/>
    <Box className={"ag-theme-alpine"} sx={{height:'365px',width: '100%'}}>
      <StyledAgGridReact
        ref={gridRef}
        rowSelection='multiple'
        rowData={!Utils.isEmpty(list.order) ? list.order?.data[0]?.data.content : []}
        columnDefs={columnDef}
        pagination={false}
        onSelectionChanged={onSelectionChange}
        stopEditingWhenCellsLoseFocus
        suppressRowClickSelection={true}
        suppressPaginationPanel={true}
        overlayLoadingTemplate={`<div class="loader"></div>`}
      />
      
       <Box style={styles.alignBottom}>
          <GridPagination
            pageClick={handleGridPagination}
            gridTotal={getTotalPages}
            currentPage={currentPage}
            onSelectionChanged={onSelectionChange}
            pageSize={GRID_FETCH_SIZE}
            gotoKeyDown={gotoKeyDown} />
          <Buttons handleGridAdd={handleGridAdd} handleGridSave={handleGridSave} handleGridEdit={handleGridEdit} handleGridDelete={handleGridDelete} labelIds={labelIds} />
      </Box>
    </Box>
  </Box>
)
}
export default LabelAssignment;